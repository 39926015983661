<template>
	<div class="booking-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'booking-edit' ? $t("titles.editBooking") : $t("titles.createBooking")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$route.name === 'booking-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="hasFetchedData === false" />
		<template v-else>
			<div class="row pt-4">
				<div class="col-md-4">
					<form-container>
						<div class="part-label col-12">{{$t("titles.bookingDetails")}}</div>
						<entity-select v-if="hasGlobalSuperAdmin" :label='$t("labels.district")' v-model='district' class="col-md-12" entity="District" search-property="name" display-property="name" value-property="@id" :disabled="true" />
						<entity-select :label='$t("labels.issuer")' v-model='issuer' class="col-md-12" entity="User" search-property="name" display-property="name" value-property="@id" />
						<field-input v-model="casenumber" :label='$t("labels.casenumber")' class="col-md-12" />
					</form-container>
				</div>
				<div class="col-md-8">
					<div class="py-4">
						<google-map
								containerStyles="width: 100%; height: 100%; position: absolute;"
								:map-config='{
									zoom: 8,
									center: {lat: parseFloat(lat), lng: parseFloat(lng)},
									zoomControl: false,
									mapTypeControl: false,
									scaleControl: false,
									streetViewControl: false,
									rotateControl: false,
									fullscreenControl: false,

									options: {
										gestureHandling: "greedy" //
									},
								}'
								:libraries="['drawing']">
							<template  slot-scope="{ google, map }">
								<map-marker :google="google" :map="map" :position="{lat: parseFloat(lat), lng: parseFloat(lng)}" />
							</template>
						</google-map>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import FieldSelect from "@/components/inputs/FieldSelect";
	import SmallButton from "@/components/buttons/SmallButton";
	import MapWithDetails from '@/components/modules/MapWithDetails.vue';
	import GoogleMap from '@/components/maps/GoogleMap.vue';
	import MapMarker from '@/components/maps/MapMarker.vue';
	import EventBus from '@/event-bus';

	export default {
		name: "AddEditBooking",
		components: {EntitySelect, Spinner, StandardButton, RegularInput, FormContainer, SmallButton, MapWithDetails, GoogleMap, MapMarker, FieldInput, FieldSelect},
		data(){
			return {
				hasFetchedData: false,
				responseError: '',
				loader: false,
				issuer: '',
				issuerError: '',
				district: '',
				districtError: '',
				bookingSegments: [],
				casenumber: '',
				lat: '0',
				lng: '0'
			}
		},
		computed: {
			...mapState('concrete', {
				bookings: 'bookings'
			}),
			...mapGetters('concrete', {
				hasGlobalSuperAdmin: 'hasGlobalSuperAdmin'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.bookings[this.$route.params.id] !== 'undefined') ? this.bookings[this.$route.params.id] : false ;
			}
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'Booking'
					},
					method: (this.$route.name === 'booking-edit') ? 'put' : 'post',
					payload: {
						issuer: this.issuer,
						district: this.district,
						lat: this.lat.toString(),
						lng: this.lng.toString(),
						casenumber: this.casenumber
					}
				};
				if(this.$route.name === 'booking-edit')
					payload.entity.id = this.$route.params.id;
				return payload;
			},
			attemptSubmit(){
				if(this.loader) return;
				// @todo update to correct entity
				this.validateInputs();
				if(this.issuerError === '' && this.districtError === '')
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							// @todo handle success feedback
							if(this.$route.name === 'booking-add')
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.bookingWasCreatedTitle"),
									text: this.$t("alerts.bookingWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.push({ name: 'entity-list', params: {entityType: 'bookings',lang: this.$i18n.locale} })
									}
								});
							}
							else
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.bookingWasUpdatedTitle"),
									text: this.$t("alerts.bookingWasUpdatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((response) => {
									if (response.isConfirmed) {
										this.$router.push({ name: 'booking-view', params: {id: result.id,lang: this.$i18n.locale} })
									}
								});
							}
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined')
						{
							// @todo Handle specific errors
							this.responseError = 'An error occurred. Please try again.';
						}
						else
						{
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				// @todo update to correct entity
				this.issuerError = '';
				if(this.issuer === '')
					this.issuerError = this.$t("validation.pleaseSelectIssuer");
				if(this.district === '')
					this.districtError = this.$t("validation.pleaseSelectDistrict");
			},
			fetchRouteEntity(){
				if(this.routeEntity === false)
				{
					this.hasFetchedData = false;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Booking'}})
					.then(result => {
						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.hasFetchedData = true;
					},() => {
						this.hasFetchedData = true;
					})
				}
				else
				{
					this.updateLocalValues();
					this.hasFetchedData = true;
				}
			},
			updateLocalValues(){
				// @todo update to correct entity
				this.issuer = typeof this.routeEntity.issuer === 'string' ? this.routeEntity.issuer : this.routeEntity.issuer['@id'] ;
				this.district = this.routeEntity.district;
				this.bookingSegments = this.routeEntity.bookingSegments;
				this.lat = this.routeEntity.lat;
				this.lng = this.routeEntity.lng;
				this.casenumber = this.routeEntity.casenumber;
			}
		},
		mounted()
		{
			if(typeof this.$route.params.id !== 'undefined')
			{
				this.fetchRouteEntity();
			}
			else
			{
				this.hasFetchedData = true;
			}
			
			EventBus.$on('EVENT_NAME', (payLoad) => {
				this.attemptSubmit();
			});
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
