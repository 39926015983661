<template>
	<div class="booking-segment-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$t("titles.editBookingSegment")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$t('terms.update')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="hasFetchedData === false" />
		<template v-else>
			<div class="row pt-4">
				<div class="col-md-12">
					<form-container>
						<div class="part-label col-12">{{$t("titles.bookingSegmentDetails")}}</div>
						<entity-select :payLoadParams="{ district: this.districtId }" :label='$t("labels.machine")' v-model='machine' class="col-md-12" entity="Machine" search-property="name" display-property="name" value-property="@id" :error="machineError" />
						<entity-select :payLoadParams="{ districts: this.districtId }" :label='$t("labels.pilot")' v-model='pilot' class="col-md-12" entity="User" search-property="name" display-property="name" value-property="@id" :error="pilotError" />
					</form-container>
				</div>
			</div>
		</template>
	</div>
</template>


<script>
	
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import FieldSelect from "@/components/inputs/FieldSelect";
	import SmallButton from "@/components/buttons/SmallButton";
	import MapWithDetails from '@/components/modules/MapWithDetails.vue';
	import GoogleMap from '@/components/maps/GoogleMap.vue';
	import MapMarker from '@/components/maps/MapMarker.vue';
	import EventBus from '@/event-bus';
	import {extractIdFromIri} from '@/helperFunctions.js';

	export default {
		name: "EditBookingSegment",
		components: {EntitySelect, Spinner, StandardButton, RegularInput, FormContainer, SmallButton, MapWithDetails, GoogleMap, MapMarker, FieldInput, FieldSelect},
		data(){
			return {
				hasFetchedData: false,
				responseError: '',
                loader: false,
                pilot: '',
				pilotError: '',
				machine: '',
				machineError: '',
				districtId: '',
			}
		},
		computed: {
			...mapState('concrete', {
				bookingSegments: 'bookingSegments'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.bookingSegments[this.$route.params.id] !== 'undefined') ? this.bookingSegments[this.$route.params.id] : false ;
			}
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'BookingSegment'
					},
					method: 'put',
					payload: {
						pilot: this.pilot,
						machine: this.machine
					}
				};

                payload.entity.id = this.$route.params.id;

                return payload;
			},
			attemptSubmit(){
				if(this.loader) return;
				// @todo update to correct entity
				this.validateInputs();
				if(this.pilotError === '' && this.machineError === '')
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
                            this.$swal.fire({
                                icon: 'success',
                                title: this.$t("alerts.bookingWasCreatedTitle"),
                                text: this.$t("alerts.bookingWasCreatedDescription"),
                                confirmButtonText: this.$t("terms.ok"),
                                confirmButtonColor: "#495760"
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.$router.push({ name: 'entity-list', params: {entityType: 'bookings',lang: this.$i18n.locale} })
                                }
                            });
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined')
						{
							// @todo Handle specific errors
							this.responseError = 'An error occurred. Please try again.';
						}
						else
						{
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				this.pilotError = '';
				this.machineError = '';
				if(this.pilot === '')
					this.pilotError = this.$t("validation.pleaseSelectPilot");
				if(this.machine === '')
					this.machineError = this.$t("validation.pleaseSelectMachine");
			},
			fetchRouteEntity(){
				if(this.routeEntity === false || typeof this.routeEntity.booking === 'string')
				{
					this.hasFetchedData = false;
					this.$store.dispatch('concrete/genericEntityRequest',{parameters: {explode:'booking:district'},entity: {'id': this.$route.params.id,'@type':'BookingSegment'}})
					.then(result => {
						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.hasFetchedData = true;
					},() => {
						this.hasFetchedData = true;
					})
				}
				else
				{
					this.updateLocalValues();
					this.hasFetchedData = true;
				}
			},
			updateLocalValues(){
				this.machine = this.routeEntity.machine !== null ? this.routeEntity.machine : '';
				this.pilot = this.routeEntity.pilot !== null ? this.routeEntity.pilot : '';
				this.districtId = extractIdFromIri(this.routeEntity.booking.district);
			}
		},
		mounted()
		{
			if(typeof this.$route.params.id !== 'undefined')
			{
				this.fetchRouteEntity();
			}
			else
			{
				this.hasFetchedData = true;
			}
			
			EventBus.$on('EVENT_NAME', (payLoad) => {
				this.attemptSubmit();
			});
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
