var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-add-edit"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3"},[_c('div',{staticClass:"h3 mb-0"},[_vm._v(_vm._s(_vm.$route.name === 'booking-edit' ? _vm.$t("titles.editBooking") : _vm.$t("titles.createBooking")))]),_c('div',{staticClass:"btn-toolbar mb-2 mb-md-0"},[_c('small-button',{attrs:{"text":_vm.$route.name === 'booking-edit' ? _vm.$t('terms.update') : _vm.$t('terms.create'),"loader-active":_vm.loader},nativeOn:{"click":function($event){return _vm.attemptSubmit.apply(null, arguments)}}})],1)]),(_vm.hasFetchedData === false)?_c('spinner'):[_c('div',{staticClass:"row pt-4"},[_c('div',{staticClass:"col-md-4"},[_c('form-container',[_c('div',{staticClass:"part-label col-12"},[_vm._v(_vm._s(_vm.$t("titles.bookingDetails")))]),(_vm.hasGlobalSuperAdmin)?_c('entity-select',{staticClass:"col-md-12",attrs:{"label":_vm.$t("labels.district"),"entity":"District","search-property":"name","display-property":"name","value-property":"@id","disabled":true},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}}):_vm._e(),_c('entity-select',{staticClass:"col-md-12",attrs:{"label":_vm.$t("labels.issuer"),"entity":"User","search-property":"name","display-property":"name","value-property":"@id"},model:{value:(_vm.issuer),callback:function ($$v) {_vm.issuer=$$v},expression:"issuer"}}),_c('field-input',{staticClass:"col-md-12",attrs:{"label":_vm.$t("labels.casenumber")},model:{value:(_vm.casenumber),callback:function ($$v) {_vm.casenumber=$$v},expression:"casenumber"}})],1)],1),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"py-4"},[_c('google-map',{attrs:{"containerStyles":"width: 100%; height: 100%; position: absolute;","map-config":{
								zoom: 8,
								center: {lat: parseFloat(_vm.lat), lng: parseFloat(_vm.lng)},
								zoomControl: false,
								mapTypeControl: false,
								scaleControl: false,
								streetViewControl: false,
								rotateControl: false,
								fullscreenControl: false,

								options: {
									gestureHandling: "greedy" //
								},
							},"libraries":['drawing']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
							var google = ref.google;
							var map = ref.map;
return [_c('map-marker',{attrs:{"google":google,"map":map,"position":{lat: parseFloat(_vm.lat), lng: parseFloat(_vm.lng)}}})]}}])})],1)])])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }